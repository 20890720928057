import GoBack from "components/GoBack"
import PageHeader from "components/PageHeader"
import ValueGroup from "../components/ValueGroup"
import useDetails from "./hooks"

import "./Details.styl"
import Can from "components/Can"

const Details = ({ pageContext: { business } }) => {
  const { values } = useDetails()

  return (
    <Can
      business={business.business_permission}
      see="remuneration"
      otherwise={{ goTo: ROUTES.home.path }}
    >
      <div className="remuneration-details">
        <GoBack />
        <PageHeader title="Detalhes de Remuneração" />
        <main className="remuneration-details__content">
          <section className="remuneration-details__section">
            <ValueGroup
              className="remuneration-details__value-group"
              title="Remuneração por originação"
              values={values.remunerationByOrigination}
            />
            <ValueGroup
              className="remuneration-details__value-group"
              title="Remuneração por campanha"
              values={values.remunerationByCampaign}
            />
            <ValueGroup
              className="remuneration-details__value-group"
              title="Remuneração por carteira em dia"
              values={values.walletDailyRemuneration}
            />
            <ValueGroup
              className="remuneration-details__value-group"
              title="Atraso curto"
              values={values.nonPaymentShort}
            />
            <ValueGroup
              className="remuneration-details__value-group"
              title="Atraso longo"
              values={values.nonPaymentLong}
            />
            <ValueGroup
              className="remuneration-details__value-group"
              title="Cálculo"
              values={values.rate}
            />
          </section>
          <section className="remuneration-details__section remuneration-details__section--emphasis">
            <ValueGroup
              className="remuneration-details__value-group--emphasis"
              title="Remuneração total"
              values={values.totalRemuneration}
            />
          </section>
        </main>
      </div>
    </Can>
  )
}

export default Details
