import { useState, useEffect, useCallback } from "react"
import services from "services"

const useDetails = () => {
  const [values, setValues] = useState({})

  const handleValues = useCallback(({ values }) => {
    setValues({
      walletDailyRemuneration: values.wallet_daily_remuneration,
      nonPaymentShort: values.non_payment_short,
      nonPaymentLong: values.non_payment_long,
      rate: values.rate,
      totalRemuneration: values.total_remuneration,
      remunerationByOrigination: values.remuneration_by_origination,
      remunerationByCampaign: values.remuneration_by_campaign,
    })
  }, [])

  const fetchValues = useCallback(() => {
    services.microloans.remuneration.values().then(handleValues)
  }, [handleValues])

  useEffect(() => {
    fetchValues()
  }, [fetchValues])

  return { values }
}

export default useDetails
