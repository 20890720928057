import classnames from "classnames"
import If from "components/If"
import Hint from "components/Hint"
import Icon from "components/Icon"

import "./ValueGroup.styl"

const ValueGroup = ({ className, title = "Valores", values = [] }) => (
  <div className={classnames("value-group", className)}>
    <h3 className="value-group__title">{title}</h3>
    <ul className="value-group__list">
      {values.map((item) => (
        <li className="value-group__item" key={item?.label}>
          <span className="value-group__label">
            <p>{item?.label}</p>
            <If condition={item?.hint}>
              <span className="value-group__hint">
                <Icon name="circle-question-mark" />
                <Hint horizontal="right" isSideways={true}>
                  {item?.hint}
                </Hint>
              </span>
            </If>
          </span>
          <p
            className={classnames("value-group__value", {
              [`value-group__value--${item?.difference}`]:
                item?.value && item?.difference,
            })}
          >
            {item?.value || "ND"}
          </p>
        </li>
      ))}
    </ul>
  </div>
)

export default ValueGroup
