import { navigate } from "gatsby"
import Icon from "components/Icon"

import "./GoBack.styl"

const GoBack = () => (
  <button onClick={() => navigate(-1)} className="go-back">
    <Icon name="arrow-left" />
    Voltar
  </button>
)

export default GoBack
